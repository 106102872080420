<template>
  <div class="wrapper">
    <header class="header">
      <div class="container">
        <div class="header-content">
          <div class="logo">
            <img :src="logo" alt="Miracle White" />
          </div>
          <!-- 汉堡菜单按钮（移动端显示） -->
          <button class="hamburger" @click="isNavOpen = !isNavOpen">
            <span class="line"></span>
            <span class="line"></span>
            <span class="line"></span>
          </button>
          <!-- 导航链接 -->
          <nav class="nav" :class="{ 'nav-open': isNavOpen }">
            <a href="#">About Us</a>
            <a href="#">NEW</a>
            <a href="#">Product</a>
            <a href="#">Product Authentication</a>
            <a href="#">Contact Us</a>
          </nav>
        </div>
      </div>
    </header>

    <main>
      <section class="verify-section">
        <div class="container">
          <h1 class="attention-title">{{ isFirstScan ? 'Congratulations!' : 'Attention!' }}</h1>
          
          <div class="verify-result" :class="{ warning: !isFirstScan }">
            <img :src="isFirstScan ? validIcon : invalidIcon" 
                :alt="isFirstScan ? 'Valid' : 'Invalid'" 
                class="valid-icon" />
            
            <template v-if="isFirstScan">
              <p class="verify-text">Your Miracle White product has been</p>
              <p class="verify-text">successfully verified as genuine. Thank</p>
              <p class="verify-text">you for choosing Hess & Co.</p>
              <p class="verify-text">Laboratories. You can now be assured</p>
              <p class="verify-text">of the premium quality and</p>
              <p class="verify-text">exceptional care that our products are</p>
              <p class="verify-text">known for.</p>
            </template>
            
            <template v-else>
              <!-- 使用新的样式包装警告文字 -->
              <div class="warning-text-wrapper">
                <p class="verify-text">This Miracle White product has been scanned multiple times.</p>
                <p class="verify-text">For your safety and to ensure you receive genuine products,</p>
                <p class="verify-text">please verify the source of your purchase.</p>
              </div>
            </template>
          </div>
        </div>
      </section>

      <section class="product-showcase">
        <div class="container">
          <h3>Radiant Futures, Infinite Beauty:</h3>
          <h3>Ignite Your Journey</h3>
          
          <div class="brand-title">
            <img :src="miracleWhite" alt="MIRACLE WHITE">
          </div>
          
          <div class="product-grid">
            <div class="product-card">
              <div class="badge-wrapper">
                <img :src="bestSellerBadge" alt="Best Seller" class="badge-icon">
              </div>
              <img :src="productMW" alt="Signature Radiance" class="product-image">
              <h4>Signature Radiance<br>Integral Care</h4>
            </div>
            
            <div class="product-card">
              <div class="badge-wrapper">
                <img :src="newBadge" alt="New" class="badge-icon">
              </div>
              <img :src="productCR" alt="Cell Renewal" class="product-image">
              <h4>Cell Renewal<br>Booster</h4>
            </div>
          </div>
        </div>
      </section>
    </main>

    <footer>
      <div class="container">
        <p>© Copyright 2012 - {{ new Date().getFullYear() }} | All Rights Reserved by Hess & Co.</p>
      </div>
    </footer>
  </div>
</template>

<script>
import logo from '@/assets/MW.png'
import validIcon from '@/assets/valid.png'
import invalidIcon from '@/assets/invalid.png'
import bestSellerBadge from '@/assets/best-seller.png'
import newBadge from '@/assets/new.png'
import productMW from '@/assets/MW-SRIC-1.png'
import productCR from '@/assets/MW-CRB.png'
import miracleWhite from '@/assets/miracle-white.png'

export default {
  name: 'App',
  data() {
    return {
      isFirstScan: true,
      isNavOpen: false, // 用于控制菜单显示状态
      logo, validIcon, invalidIcon, bestSellerBadge, newBadge, productMW, productCR, miracleWhite
    }
  },
  async mounted() {
    try {
      const code = window.location.pathname.slice(1)
      if (code) {
        const response = await fetch(`/verify/${code}`)
        const data = await response.json()
        this.isFirstScan = data.is_first_scan
      }
    } catch (error) {
      console.error('Verification error:', error)
      this.isFirstScan = false
    }
  }
}
</script>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.header {
  padding: 20px 0;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  position: relative;
}

.header-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative; /* 为移动端绝对定位nav提供参照 */
}

.logo img {
  height: 60px;
}

.nav {
  display: flex;
  gap: 30px;
}

.nav a {
  color: #333;
  text-decoration: none;
  font-size: 16px;
}

/* 汉堡菜单样式 */
.hamburger {
  display: none;
  background: none;
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 100;
}
.hamburger .line {
  display: block;
  width: 25px;
  height: 3px;
  background: #333;
  margin: 5px 0;
  transition: all 0.3s ease;
}

/* 移动端响应 */
@media (max-width: 768px) {
  .attention-title {
    font-size: 32px;
    padding: 0 20px;
  }
  
  .verify-text {
    max-width: 90%;
  }

  /* 默认隐藏导航 */
  .nav {
    position: fixed;
    top: 0;
    right: -100%;  /* 初始状态在屏幕右侧隐藏 */
    width: 70%;    /* 菜单宽度 */
    max-width: 300px;
    height: 100vh;
    background: #fff;
    flex-direction: column;
    padding: 80px 20px 20px;
    box-shadow: -2px 0 4px rgba(0,0,0,0.1);
    transition: right 0.3s ease;
    z-index: 90;
  }

  /* 当.nav有.nav-open类时显示菜单 */
  .nav.nav-open {
    right: 0;
  }

  .nav a {
    padding: 10px 0;
    border-bottom: 1px solid #eee;
    width: 100%;
    text-align: left;
  }
  .nav a:last-child {
    border-bottom: none;
  }
  /* 添加遮罩层 */
  .nav-open::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.5);
    z-index: -1;
  }
}

/* 桌面端保持现状 */
@media (min-width: 769px) {
  .hamburger {
    display: none; /* 桌面端隐藏汉堡按钮 */
  }

  .nav {
    display: flex;
    position: static;
    padding: 0;
    background: none;
    box-shadow: none;
    width: auto;
    height: auto;
  }
}

/* 验证结果区域的样式 */
.verify-section {
  text-align: center;
  padding: 60px 0;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.attention-title {
  font-family: serif;
  font-size: 36px;
  text-align: center;
  margin: 0 auto 40px;
  color: #333;
  width: 100%;
  max-width: 495px;
}

.verify-result.warning .verify-text {
  color: #333;
  text-align: center;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 1.5;
  margin: 0;
}

.warning .valid-icon {
  margin-bottom: 30px;
}

.verify-section h1 {
  font-size: 36px;
  margin-bottom: 20px;
  font-family: serif;
}

.verify-text {
  text-align: center;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  color: #333;
  line-height: 1.5;
  margin: 0;
  max-width: 80%;
}

.product-showcase h3 {
  font-family: serif;
  color: #414042;
}

/* 针对 .elementor-6008 .elementor-element 下的 h3 */
.elementor-6008 .elementor-element h3 {
  font-size: 28px;
  color: #333;
  line-height: 1.4;
  font-family: serif;
}

/* 针对文字修饰 */
.elementor-widget-text-editor {
  text-align: center;
  font-weight: 600;
  line-height: 1.2em;
}

.verify-result {
  margin-top: 40px;
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.warning-text-wrapper {
  margin: auto;
  width: 80%;
  max-width: 480px;
}

.warning-text {
  text-align: center;
  color: #333;
  line-height: 1.5;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  margin: 0;
}

.valid-icon {
  width: 12%;
  min-width: 100px;
  height: auto;
  margin: 30px 0;
}

.verify-result h2 {
  margin-bottom: 15px;
}

.product-showcase {
  padding: 60px 0;
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
}

.product-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px;
  margin: 60px auto 0;
  padding: 100px 20px 0;
}

/* 徽章容器 */
.badge-wrapper {
  position: relative;
  height: 100px;
  margin-bottom: 40px;
}

/* 徽章样式 */
.badge-icon {
  position: absolute;
  top: -80px;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  height: auto;
}

.product-card {
  position: relative;
  text-align: center;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.product-image {
  width: 100%;
  max-width: 250px;
  margin: 0 auto 25px;
}

.product-card h4 {
  font-size: 20px;
  margin-bottom: 5px;
  color: #333;
  font-weight: normal;
}

.product-card p {
  color: #666;
  font-size: 16px;
  font-weight: 300;
}

.brand-title {
  margin: 80px 0;
  text-align: center;
}

.brand-title img {
  max-width: 300px;
  height: auto;
}

@media (max-width: 768px) {
  .product-grid {
    padding-top: 80px;
  }
  
  .badge-wrapper {
    height: 80px;
    margin-bottom: 30px;
  }

  .badge-icon {
    width: 80px;
    top: -60px;
  }

  .product-card {
    margin-top: 15px;
  }
}

footer {
  margin-top: auto;
  background: #404042;
  color: #ffffff;
  padding: 20px 0;
}

footer .container {
  text-align: center;
}

footer p {
  margin: 0;
  font-size: 14px;
}
</style>

